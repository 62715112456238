@import "jit-refresh.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter var';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/inter/Inter-roman.var.woff2?v=3.19') format('woff2');
  font-named-instance: 'Regular';
}

@font-face {
  font-family: 'Inter var';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('../fonts/inter/Inter-italic.var.woff2?v=3.19') format('woff2');
  font-named-instance: 'Italic';
}
